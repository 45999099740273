<template>
  <v-container
    fluid
    id="regular-table"
  >
    <div
      class="ma-3 d-flex flex-column flex-md-row"
      style="gap: 30px"
    >
      <v-flex xs6>
        <h3 class="text-h4 mb-4">{{ $t("stockAdjustments.adjustment_details") }}</h3>
        <div class="ma-4">
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("stockAdjustments.inventory_number") }}</span>
            <span class="text-h4">
              {{ stockAdjustment?.inventory_number ?? "-" }}
            </span>
          </div>
          <v-divider></v-divider>
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("stockAdjustments.hub") }}</span>
            <span class="text-h4">
              {{ stockAdjustment?.hub?.name ?? "-" }}
            </span>
          </div>

          <v-divider></v-divider>
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("stockAdjustments.note") }}</span>
            <span class="text-h4">
              {{ stockAdjustment?.note ?? "-" }}
            </span>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h4 d-flex justify-space-between py-4"
            style="min-width: 200px"
          >
            <span class="mt-2">{{ $t("stockAdjustments.adjustment_slip") }}</span>
            <v-btn
              small
              :loading="isLoading"
              rounded
              class="primary"
              text
              @click="downloadStockAdjustmentNot(stockAdjustment)"
            >
              {{ "delivery-note-" + stockAdjustment.tracking_number + ".pdf" }}
              <v-icon class="ma-2"> mdi-download </v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          </div>
      </v-flex>
      <v-flex xs6>
        
        <div class="ma-4">
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("stockAdjustments.creation_date") }}</span>
            <span class="text-h4">
              {{ stockAdjustment?.created_at ?? "-" }}
            </span>
          </div>
          <v-divider></v-divider>
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("stockAdjustments.adjusted_by") }}</span>
            <span class="text-h4 custom_capitalize">
              {{ stockAdjustment?.created_by?.name ?? "-" }}
            </span>
          </div>
          <v-divider></v-divider>

          <div v-if="stockAdjustment?.stock_adjustment_status == 'closed'">
            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("stockAdjustments.status") }}</span>
              <span :class="{'text-h4':true ,'green--text': true , 'custom_capitalize':true }">
                {{ stockAdjustment?.stock_adjustment_status ?? "-" }}
              </span>
            </div>
          <v-divider></v-divider>
            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("stockAdjustments.validated_by") }}</span>
              <span class="text-h4 custom_capitalize">
                {{ stockAdjustment?.checked_by?.name ?? "-" }}
              </span>
            </div>
          <v-divider></v-divider>
          </div>

          <div v-if="stockAdjustment?.stock_adjustment_status == 'canceled'">
            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("stockAdjustments.status") }}</span>
              <span :class="{'text-h4':true ,'red--text': true, 'custom_capitalize':true}">
                {{ stockAdjustment?.stock_adjustment_status  ?? "-" }}
              </span>
            </div>
          <v-divider></v-divider>

          <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("stockAdjustments.canceled_note") }}</span>
              <span class="text-h4">
                {{ stockAdjustment?.canceled_note ?? "-" }}
              </span>
            </div>
          <v-divider></v-divider>

            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("stockAdjustments.canceled_by") }}</span>
              <span class="text-h4 custom_capitalize">
                {{ stockAdjustment?.canceled_by?.name ?? "-" }}
              </span>
            </div>
          <v-divider></v-divider>
          </div>
        </div>
      </v-flex>
    </div>
    <div>
      <v-card>
        <v-card-title class="text-h4">
          Item List ({{ stockAdjustment.products_count }})
        </v-card-title>
        <br />
        <div class="ms-5">
          <v-data-table
            :items="stockAdjustment.products"
            :headers="_headers"
            item-key="id"
            class="elevation-0"
            show-expand
            single-expand
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>

            <template v-slot:item.theoretical_stock="{ item }">
              {{
                Number(
                  item.lots.reduce((partialSum, a) => partialSum + a.theoretical_qte , 0))?.toFixed(0)
              }}
            </template>

            <template v-slot:item.real_stock="{ item }">
              {{
                Number(
                  item.lots.reduce((partialSum, a) => partialSum + a.real_qte , 0))?.toFixed(0)
              }}
            </template>

            <template v-slot:item.difference="{ item }">

              <span :class="{
                            'green--text': sumLots(item) > 0,
                            'red--text': sumLots(item)< 0,
                          }">
              {{
               sumLots(item) > 0 ? "+"+sumLots(item) : sumLots(item)
              }}
              </span>
            </template>

            <template v-slot:item.difference_value="{ item }">
                {{  sumLotsValue(item) > 0 ? "+"+sumLotsValue(item) : sumLotsValue(item) }}
            </template>
            <!-- expand column -->
            <template
              v-slot:item.data-table-expand="{ item, isExpanded, expand }"
            >
              <v-icon
                @click="expand(true)"
                v-if="item.lots && !isExpanded"
                >mdi-chevron-down</v-icon
              >

              <v-icon
                @click="expand(false)"
                v-if="item.lots && isExpanded"
                >mdi-chevron-up</v-icon
              >
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table
                  class="my-5"
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("stockAdjustments.lot_number") }}
                        </th>
                        <th class="text-left">
                          {{ $t("stockAdjustments.dlc") }}
                        </th>
                        <th class="text-left">
                          {{ $t("stockAdjustments.real_dlc") }}
                        </th>
                        <th class="text-left">
                          {{ $t("stockAdjustments.unite_price_ht") }}
                        </th>
                        <th class="text-left">
                          {{ $t("stockAdjustments.theoretical_stock") }}
                        </th>
                        <th class="text-left">
                          {{ $t("stockAdjustments.real_stock") }}
                        </th>
                        <th class="text-left">
                          {{ $t("stockAdjustments.difference") }}
                        </th>
                         <th class="text-left">
                          {{ $t("stockAdjustments.comment") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="lot in item.lots"
                        :key="lot.id"
                      >
                        <td>{{ lot.lot_number }}</td>
                        <td>{{ formatDate(lot.theoretical_expiration_date) }}</td>
                        <td>{{ formatDate(lot.real_expiration_date) }}</td>
                        <td>{{ lot.lot_price_ht }}</td>
                        <td>{{ lot.theoretical_qte }}</td>
                        <td>{{ lot.real_qte }}</td>
                        <td :class="{
                            'green--text': lot.gap_qte > 0,
                            'red--text': lot.gap_qte < 0,
                          }">{{ lot.gap_qte > 0 ? "+"+ lot.gap_qte : lot.gap_qte  }}</td>
                        <td>{{ lot.gap_note }}</td>


                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <div class="mt-8 d-flex flex-row-reverse">
        <v-btn 
          v-if="$admin.can('stockAdjustment-validate') && stockAdjustment?.stock_adjustment_status == 'pending'"
          class="ml-4 primary"
          text
          @click.stop="validate()"
        >
        {{ $t("stockAdjustments.approve") }}
        </v-btn>
        <v-btn
          v-if="stockAdjustment?.stock_adjustment_status == 'pending'"  
          class="ml-4 custom_danger_btn"
          text
          @click="cancel()"
        >
        {{ $t("stockAdjustments.reject") }}
        </v-btn>
        <v-btn 
          class="ml-4 custom_info_btn"
          text
          @click="close()"
        >
        {{ $t("stockAdjustments.back") }}
        </v-btn>
      </div>
    </div>
    <validate-modal
      v-if="isModalOpen && $admin.can('stockAdjustment-validate')"
      :isModalOpen="isModalOpen"
      :adjustmentItem="currentAdjustment_item"
      :toggleModal="toggleModal"
      :adjustmentListFilters="{ ...this.options, ...this.filters }"
      :return_to_root="root_to_return"
    >
    </validate-modal>
    <cancel-adjustment-stock-modal
      v-if="$admin.can('stockAdjustment-delete') && isDeleteModalOpen"
      :isDeleteModalOpen="isDeleteModalOpen"
      :stockAdjustment="currentAdjustment_item"
      :toggleDeleteModal="() => toggleDeleteModal()"
      :return_to_root="root_to_return"
    ></cancel-adjustment-stock-modal>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import stockAdjustmentService from "@/store/services/stock-adjustment-service";
import moment from "moment";

export default {
  components: {
    ValidateModal: () => import("./modals/ValidateAdjustment.vue"),
    CancelAdjustmentStockModal: () =>
      import("./modals/CancelAdjustmentStockModal"),
  },
  computed: {
    ...mapGetters({
      stockAdjustment: "stockAdjustments/stockAdjustment",
    }),
    _headers() {
      if (
        this.transfer?.transferStatus?.name == "closed" ||
        this.transfer?.transferStatus?.name == "canceled" ||
        this.transfer?.transferStatus?.name == "archived"
      )
        return this.headers.filter((x) => x.show);
      else return this.headers;
    },
  },

  data() {
    return {
      options:null,
      filters:null,
      isLoading: false,
      show_details: false,
      isDeleteModalOpen: false,
      isModalOpen: false,
      currentAdjustment_item : null,
      root_to_return: "stock-adjustments",

      headers: [
        { text: this.$t("image"), value: "image",sortable: false, align: "start", show: true },
        {
          text: this.$t("name"),
          sortable: false,
          value: "name",
          show: true,
        },
        { 
          text: this.$t("stockAdjustments.theoretical_stock"), 
          value: "theoretical_stock", 
          sortable: false,
          show: true 
        },
        {
          text: this.$t("stockAdjustments.real_stock"),
          value: "real_stock",
          sortable: false,
          show: true,
        },
        { 
          text: this.$t("stockAdjustments.difference"), 
          value: "difference", 
          sortable: false,
          show: true 
        },
        {
          text: this.$t("stockAdjustments.difference_value"),
          value: "difference_value",
          sortable: false,
          show: true,
        },
      ]
    };
  },

  methods: {
    validate() {
      this.isModalOpen = true;
      this.currentAdjustment_item = this.stockAdjustment;
    },
    cancel() {
      this.isDeleteModalOpen = true;
      this.currentAdjustment_item = this.stockAdjustment;
    },

    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
      this.$router.push("/stock-adjustments");
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },

    sumLotsValue(item){
    return Number(
                  item.lots.reduce((partialSum, a) => partialSum + ((a.real_qte - a.theoretical_qte) * a.lot_price_ht) , 0))?.toFixed(2) || 0;
    },
    sumLots(item){
    return item.lots.reduce((partialSum, a) => partialSum + a.gap_qte , 0) || 0;
    },
    formatDate(date) {
      if (!date) return "-";
      return moment(date).format("DD/MM/YYYY");
    },
    async downloadStockAdjustmentNot(stockAdjustment) {
      this.isLoading = true;
      const BDC = await stockAdjustmentService.getAdjustmentSlip(stockAdjustment);
      const url = window.URL.createObjectURL(new Blob([BDC]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${stockAdjustment.tracking_number}.pdf`);
      document.body.appendChild(link);
      link.click();
      this.isLoading = false;
    },

    close() {
      console.log(this.stockAdjustment.products);
      this.$store.commit("SET_PAGE_SUBTITLE", "");
      this.$store.commit("SET_SUBTITLE_ID", "");
      this.$router.push("/stock-adjustments");

    },

    getColor(status) {
      if (status === "closed") return "green--text";
      else return "grey--text";
    },
  },

  async created() {
    const id = this.$route.params.id;
    if (id) {
      const stockAdjustmentEntity = await stockAdjustmentService.show({
        id,
        include: "hub,products,checkedBy,createdBy,canceledBy",
      });

      console.log("stockAdjustmentEntity data :" , stockAdjustmentEntity);

        this.$store.commit("stockAdjustments/STOCKADJUSTMENT", stockAdjustmentEntity);
        this.$store.commit(
          "SET_PAGE_SUBTITLE",
          stockAdjustmentEntity.tracking_number
        );
        this.$store.commit("SET_SUBTITLE_ID", "StockAdjustement ID :");
      } 
  },
};
</script>